import React, { useState } from "react";

import useTranslations from "../useTranslations";
import { sendEvent } from "../../utils/googleMeasurementProtocol";

const Newsletter = () => {
  const { newsletter } = useTranslations();
  const [email, setEmail] = useState("");
  const [isSent, setIsSent] = useState(false);

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    window.dataLayer.push({
      event: "newsletter_signup",
      user_data: {
        email_address: email,
      },
    });
    setEmail("");
    setIsSent(true);
  };

  return (
    <div className="bg-gray-50 relative sm:pt-16">
      <div className="relative px-6 py-10 bg-cyan-600 overflow-hidden shadow-xl sm:px-12 sm:py-20">
        <div
          aria-hidden="true"
          className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0"
        >
          <svg
            className="absolute inset-0 h-full w-full"
            preserveAspectRatio="xMidYMid slice"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 1463 360"
          >
            <path
              className="text-cyan-500 text-opacity-40"
              fill="currentColor"
              d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
            />
            <path
              className="text-cyan-700 text-opacity-40"
              fill="currentColor"
              d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
            />
          </svg>
        </div>
        <div className="relative">
          <div className="sm:text-center">
            <h2 className="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
              {newsletter.title}
            </h2>
            <p className="mt-6 mx-auto max-w-2xl text-lg text-cyan-200">
              {newsletter.description}
            </p>
          </div>
          {isSent ? (
            <p className="mt-12 text-xl font-medium text-white text-center">
              Thank-you for the registration
            </p>
          ) : (
            <form
              onSubmit={handleSubmitForm}
              className="mt-12 sm:mx-auto sm:max-w-lg sm:flex"
            >
              <div className="min-w-0 flex-1">
                <label htmlFor="cta-email" className="sr-only">
                  {newsletter.placeholder}
                </label>
                <input
                  id="cta-email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="block w-full border border-transparent rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600"
                  placeholder={newsletter.placeholder}
                />
              </div>
              <div className="mt-4 sm:mt-0 sm:ml-3">
                <button
                  type="submit"
                  className="block w-full rounded-md border border-transparent px-5 py-3 bg-cyan-500 text-base font-medium text-white shadow hover:bg-cyan-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-cyan-600 sm:px-10"
                >
                  {newsletter.button}
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
