import React from "react";
import useTranslations from "../useTranslations";

const HeroRecipes = () => {
  const { playbook } = useTranslations();
  return (
    <div className="bg-gradient-to-r from-teal-500 to-cyan-600">
      <div className="max-w-4xl mx-auto py-16 px-4 sm:pt-12 sm:pb-20 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="mt-1 text-2xl font-bold text-white sm:text-3xl sm:tracking-tight lg:text-4xl">
            GTM server-side Recipes
          </h1>
          <p className="max-w-xl mx-auto mt-5 text-xl text-gray-200">
            Explore popular solutions to get the most out of Google Tag Manager
            server-side
          </p>
        </div>
      </div>
    </div>
  );
};

export default HeroRecipes;
