import React from "react";
import HeroRecipes from "../components/recipes/hero";
import LocalizedLink from "../components/localizedLink";
import { graphql } from "gatsby";
import useTranslations from "../components/useTranslations";
import Newsletter from "../components/playbook/newsletter";
import { HiArrowRight } from "react-icons/all";
import HelmetHeader from "../components/helmetHeader";
import reactStringReplace from "react-string-replace";

const Recipes = ({ data: { allMdx }, location }) => {
  const { recipes, lang } = useTranslations();
  return (
    <>
      <HelmetHeader
        location={location}
        lang={lang}
        title={recipes.metas.title}
        metas={[{ name: "description", content: recipes.metas.description }]}
      />
      <HeroRecipes />
      <div className="w-full bg-gray-50">
        <div className="relative max-w-5xl mx-auto">
          <div className="pt-10 pb-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 px-4 lg:px-0 lg:max-w-none">
            {allMdx.edges.map(({ node: post }) => {
              return (
                <div
                  key={post.frontmatter.title}
                  className="flex h-full bg-white flex-col rounded-lg shadow-md transition-all hover:-mt-1 hover:mb-1 hover:shadow-lg"
                >
                  <LocalizedLink
                    to={`/${post.parent.relativeDirectory}/`}
                    className="block mt-2 h-full"
                  >
                    <div className="flex flex-col justify-between px-6 py-3 h-full">
                      <div className="flex flex-col">
                        <h3 className="mt-1 text-xl font-semibold text-gray-900">
                          {post.frontmatter.title}
                        </h3>
                        <div className="flex flex-col mt-3">
                          {post.frontmatter?.authors?.map((author, index) => {
                            return (
                              <div
                                key={index}
                                className="flex gap-3 items-center my-2"
                              >
                                <img
                                  src={author.icons.explore}
                                  alt={author.alt}
                                  className="h-6 w-6"
                                />
                                <span className="text-gray-500 font-light text-md">
                                  {reactStringReplace(
                                    recipes.madeBy,
                                    "{{authorName}}",
                                    () => (
                                      <b>{author.name}</b>
                                    )
                                  )}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                        <p className="mt-3 text-base text-gray-500">
                          {reactStringReplace(
                            post.frontmatter.description,
                            "<br/>",
                            () => (
                              <br />
                            )
                          )}
                        </p>
                      </div>
                      <div className="flex justify-between items-center pt-4 pb-2">
                        <div className="flex gap-2 items-center">
                          <span className="text-cyan-700 text-sm">
                            Use this recipe
                          </span>
                          <HiArrowRight className="text-cyan-700 h-3 w-3" />
                        </div>
                        <div className="flex items-center">
                          {post.frontmatter.icons?.map((icon, index) => {
                            return (
                              <div
                                key={index}
                                className="bg-white border rounded-full border-gray-100 -ml-3"
                              >
                                <img
                                  className="h-9 w-9 m-3"
                                  src={icon}
                                  alt="icon"
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </LocalizedLink>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Newsletter />
    </>
  );
};

export default Recipes;

export const query = graphql`
  query RecipesContent($locale: String!) {
    allMdx(
      filter: {
        fields: { locale: { eq: $locale } }
        slug: { regex: "/^recipes/" }
      }
      sort: { order: ASC, fields: frontmatter___order }
    ) {
      edges {
        node {
          frontmatter {
            title
            description
            icons
            authors {
              name
              alt
              icons {
                explore
              }
            }
          }
          fields {
            locale
          }
          parent {
            ... on File {
              relativeDirectory
              sourceInstanceName
            }
          }
        }
      }
    }
  }
`;
